import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PostLink from '@/components/Blog/Post'

const NewestPosts = ({ data, count }) => {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <div className='posts-feed'>
      {posts &&
        posts.map(({ node: post }) => (
          <PostLink key={post.id} post={post}></PostLink>
        ))}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query NewestPostsQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "Article" } } }
          limit: 3
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <NewestPosts data={data} count={count} />}
  />
)
