import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HTMLReactParser from 'html-react-parser'
import NewestPosts from '@/components/Blog/NewestPosts'
import Author from '@/components/Blog/Author'
import Layout from '@/components/Layout'
import Img from 'gatsby-image'
import SEO from '@/helpers/SEOHelper.js'

const Article = ({ data }) => {
  const { markdownRemark: post } = data
  const { edges: authors } = data.authors

  const author = authors.filter(
    (item) => item.node.frontmatter.title === post.frontmatter.author,
  )

  const seo = data.markdownRemark.frontmatter.seo

  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo.image)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>
      <div className='Article'>
        <div className='top-bg-contact top-bg bg-grey-lightest' />
        <div className='container-lg pt-56 pb-16'>
          <div className=''>
            <h1 className='leading-tight mb-5'>{post.frontmatter.title}</h1>
            <div className='text-grey-light font-heading text-sm uppercase'>
              <span className='mr-5'>{post.frontmatter.date}</span>
              <span>by {post.frontmatter.author}</span>
            </div>
          </div>
        </div>
        <div className='container-lg mb-10'>
          <Img
            className='Article__featured'
            fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
          />
        </div>
        <section className='container-sm'>
          <div className='Article__content'>{HTMLReactParser(post.html)}</div>
          <div className='Article__author'>
            a<div className='blog__line'></div>
            <h5 className='mt-5 mb-6'>About the author</h5>
            <Author author={author[0]} />
          </div>
        </section>
        <section className='container-lg py-24'>
          <NewestPosts />
        </section>
      </div>
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Article

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      internal {
        content
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        author
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seo {
          title
          description
        }
      }
    }
    authors: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "author" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            socialmedia {
              link
              icon
            }
            image {
              childImageSharp {
                fluid(maxWidth: 120, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
