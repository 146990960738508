import React from 'react'
import Img from 'gatsby-image'
import ReactSVG from 'react-svg'

const Author = ({ author }) => {
  author = author.node.frontmatter
  return (
    <>
      <div className='author'>
        <div className='author__details'>
          <div className='author__image bg-grey-lightest'>
            <Img
              fluid={author.image.childImageSharp.fluid}
              className='gatsby-wrapper-image'
            />
          </div>
          <div className='author__about'>
            <h5 className='author__name'>{author.title}</h5>
            <div className='author__description'>{author.description}</div>
          </div>
        </div>

        <div className='author__social'>
          {author.socialmedia &&
            author.socialmedia.map((item, i) => {
              return (
                <a
                  href={item.link}
                  target='__blank'
                  className='social-link'
                  noopener='noreferrer'
                  key={i}>
                  <ReactSVG src={`/img/svg/${item.icon}.svg`}></ReactSVG>
                </a>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default Author
